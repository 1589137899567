import { FC } from 'react'
import css from 'styled-jsx/css'
import SfLink from '@ui/components/next/SfLink'
import Link from 'next/link'
import Logo from './Logo'
import { useSetting, useSettingBvf } from '@ui/hooks'

const Footer: FC<Types.PlainObject> = () => {
  const settingBvf = useSettingBvf()
  const year = new Date().getFullYear()
  const [storeSetting] = useSetting('store')

  return (
    <footer className="footer-container">
      <style jsx>{globalStyle}</style>
      <div className="footer-content container-beva">
        <div className="footer-content__info">
          <Logo
            height={settingBvf?.height_logo_footer || 26}
            className="footer_logo"
            redirectable={false}
            logoSrc={settingBvf?.logo_footer ? settingBvf?.logo_footer : ''}
          />
          <p className="label-item mb-2">{settingBvf?.address}</p>
          <Link href={`mailto:${settingBvf?.email}`}>
            <a className="label-item mb-2">{settingBvf?.email}</a>
          </Link>
          <Link href={`tel:${settingBvf?.phone}`}>
            <a className="label-item">{settingBvf?.phone}</a>
          </Link>
        </div>
        <div className="footer-content__customer-care">
          <div className="label-title mb-4">Customer Care</div>
          <SfLink className="label-item mb-3 mb-4-mobile" pathName="/help-center">
            Help Center
          </SfLink>
          <SfLink className="label-item  mb-3 mb-4-mobile" pathName="/trackings/search">
            Track your order
          </SfLink>
          <SfLink className="label-item" pathName="/contact">
            Contact Us
          </SfLink>
        </div>
        <div className="footer-content__resources">
          <div className="label-title mb-4">About {storeSetting?.general?.name}</div>
          <SfLink className="label-item mb-3 mb-4-mobile" pathName="/static/about-us">
            About Us
          </SfLink>
          <SfLink className="label-item mb-3 mb-4-mobile" pathName="/static/terms-of-service">
            Terms of Service
          </SfLink>
          <SfLink className="label-item" pathName="/static/privacy">
            Privacy Policy
          </SfLink>
        </div>
      </div>

      <div className="footer-copyrights">
        <div> {`© 2020-${year} ${settingBvf?.copyright_footer}`}</div>
      </div>
    </footer>
  )
}

const globalStyle = css.global`
  .footer-container {
    padding: 64px 0px 0px;
    background-color: var(--gray-9);
    text-align: center;

    .footer-content {
      &__info {
        margin-bottom: 48px;
        .footer_logo {
          display: block;
          margin-bottom: 16px;
          .image {
            display: flex;
            justify-content: center;
            img {
              height: 36px;
              width: auto;
              object-fit: contain;
            }
          }
        }
      }
      &__customer-care {
        margin-bottom: 48px;
      }
      &__resources {
        margin-bottom: 32px;
      }

      .label-title {
        color: var(--indigo-3);
        letter-spacing: 0.2em;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
      }
      .label-item {
        color: var(--gray-4);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        display: block;
      }
    }
    .footer-copyrights {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: var(--gray-5);
      padding: 0 24px;

      max-width: 1168px;
      width: 100%;
      margin: auto;

      div {
        border-top: 1px solid #5b5b5b;
        padding: 24px 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .footer-container {
      .footer-content {
        text-align: left;
        display: flex;
        margin-bottom: 52px;

        &__info {
          margin-bottom: 0px;
          width: 42%;
          .footer_logo {
            margin-bottom: 12px;
            .image {
              justify-content: flex-start;
            }
          }
        }
        &__customer-care {
          margin-bottom: 0px;
          width: 33%;
        }
        &__resources {
          margin-bottom: 0px;
          width: 25%;
        }
      }
    }
  }
`

export default Footer
