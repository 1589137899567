import css from 'styled-jsx/css'

export const globalStyle = css.global`
  .cart {
    $padding: 30px;
    $padding-mobile: 18px;

    .cart-button {
      position: fixed;
      right: 1.5rem;
      bottom: 2rem;
      z-index: 9;

      .button {
        border-radius: 50%;
        padding: 24px 14px;
        width: 52px;
        height: 54px;

        img {
          width: 22px;
          height: 22px;
          object-fit: cover;
        }
      }

      .badge {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: solid 4px white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }
    }

    .modal {
      z-index: 1000;

      .modal-card {
        height: 100vh;
        max-height: 100vh;
        max-width: 480px;
        margin-right: 0;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          max-width: 100vw;
          width: 100vw;
        }

        .modal-card-head {
          background-color: white;
          // border-bottom: 0;
          border-radius: 0;
          padding: 40px $padding $padding;

          @media screen and (max-width: 768px) {
            padding: 8px 20px;
          }

          .modal-card-title {
            font-size: 20px;
            font-weight: bold;
          }

          .delete {
            font-size: 40px;
            height: 40px;
            max-height: 40px;
            max-width: 40px;
            min-height: 40px;
            min-width: 40px;
            width: 40px;
          }
        }

        .modal-card-body {
          padding: 0;
          position: relative;

          &.disabled {
            pointer-events: none;
          }

          .scrollable-content {
            max-height: calc(100vh - 363px + 15px);
            padding: 0px $padding 24px;
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            left: 0;
            z-index: 100;
            overflow-x: hidden;
            overflow-y: auto;

            .group-item {
              &__label {
                margin-bottom: 16px;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
              }

              &__qty {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-left: 8px;
                color: #363636;
              }

              .variant-item {
                margin-bottom: 0px;

                .variant {
                  margin-bottom: -20px;
                }
                .variant__quantity.show-upsell {
                  margin-bottom: 8px;
                }
              }

              .cart-divider {
                height: 1px;
                background: #e0e3e9;
                margin: 24px 0px 16px;
              }
            }
          }
          .fixed-bottom {
            padding: 0 $padding $padding;
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 101;

            &__line {
              margin: 0px -30px 11px;
              background: #c5cdd5;
            }
            .total {
              margin-bottom: 12px;
              text-align: right;
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #282828;

              .total-item {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
              }

              .price {
                font-weight: 700;
                margin-left: 24px;
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          .empty-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px 16px 34px;
            height: 100%;

            div {
              height: 100%;
              font-weight: 600;
              font-size: 20px;
              line-height: 32px;
              color: var(--gray-8);
              display: flex;
              align-items: center;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              color: var(--gray-8);
            }

            button {
              padding: 14px 24px;
              width: 100%;
              border-radius: 4px;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
              border: none;
              margin-top: 16px;
              cursor: pointer;
            }
          }

          @media screen and (max-width: 768px) {
            .scrollable-content {
              max-height: calc(100vh - 256px);
              max-height: calc((var(--vh, 1vh) * 100) - 256px);

              padding: 16px 12px;

              .group-item {
                .variant-item {
                  margin-bottom: 24px;

                  .variant {
                    margin-bottom: 0px;
                  }
                }

                .cart-divider {
                  height: 6px;
                  background: #d2d7de;
                  margin: 0 -12px 16px;
                }
              }
            }

            .fixed-bottom {
              hr {
                margin-bottom: 0;
              }
              background-color: white;
              padding: 0 $padding-mobile $padding-mobile;
              position: absolute;
              width: 100%;
              bottom: 0;
              z-index: 101;

              .total {
                margin: 12px 0;
                text-align: right;

                .price {
                  font-weight: bold;
                  margin-left: 24px;
                }
              }
            }
          }
        }

        .label__icon {
          display: inline-block;

          &:not(:last-child) {
            margin-left: 10px;
          }

          img {
            width: unset;
            height: 30px;
          }
        }
      }
    }
  }
`

export const orderVariantStyle = css.global`
  .order-variants {
    @media screen and (max-width: 769px) {
      padding-bottom: 10px;
      hr {
        margin: 16px 0;
      }
    }
    .variant-item:last-child {
      hr {
        display: none;
      }
    }
    &__bottom {
      margin-bottom: 0;

      @media screen and (min-width: 769px) {
        margin-bottom: 20px;
      }
    }

    .upsells {
      background: #f6f8f9;
      border: 1px dashed #1d9f54;
      border-radius: 4px;
      display: flex;
      padding: 8px 10px;
      align-items: center;
      /* margin-top: 24px; */
      @media screen and (max-width: 768px) {
        margin: 0 0 24px;
      }
      &__text {
        font-size: 15px;
        line-height: 20px;
        color: #1d9f54;
        padding-right: 8px;
        margin-right: auto;
        font-weight: 600;
        &__bold {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        &__semi-bold {
          font-weight: 600;
        }
      }
      &__button {
        background: #1d9f54;
        border: 1px solid #1d9f54;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin-left: auto;
      }
    }

    &__summary {
      margin-top: 32px;
      .item {
        display: flex;
        justify-content: flex-end;

        &__label {
          text-align: right;
          line-height: 24px;

          &--refund {
            color: #eb5757;
            margin-top: 8px;
            display: flex;
            align-items: center;
          }

          &--bold {
            font-weight: 600;

            line-height: 32px;
            margin-top: 8px;
          }
        }

        &__number {
          text-align: right;
          min-width: 80px;

          line-height: 24px;
          font-weight: 600;

          &--refund {
            color: #eb5757;
            margin-top: 8px;
          }

          &--bold {
            font-weight: 600;
            line-height: 32px;
            margin-top: 8px;
          }
        }

        &__separator {
          @media screen and (min-width: 769px) {
            display: none;
          }
        }
      }

      .countdown {
        color: var(--secondary);
        display: inline-block;
        font-weight: bold;
        min-width: 44px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }

    .modal {
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }

    .price {
      &__secondary {
        text-decoration: line-through;
      }
    }

    .remove {
      text-decoration: underline;
    }

    .refund-hint {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
    }

    .max-witdh__mobile {
      @media screen and (max-width: 321px) {
        max-width: 140px;
      }
    }

    @media screen and (min-width: 787px) {
      .variant-item:not(:last-child) .variant .variant--top {
        .variant__quantity.desktop {
          margin-bottom: 8px;
        }
      }
    }

    .variant {
      &--top {
        margin-bottom: -12px !important;
        display: inline-flex;
      }

      &__image {
        border: solid 1px #e8e8e8;
        border-radius: 5px;

        img {
          border-radius: 4px;
        }
      }

      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      &__property {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 24px;
        text-transform: capitalize;

        @media screen and (max-width: 786px) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: 575px) {
        .property,
        p {
          max-width: 230px;
        }
      }

      &__remove {
        float: right;

        @media screen and (max-width: 768px) {
          float: initial;
        }

        .button {
          font-size: 12px;
          line-height: 18px;
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }

      .btn-remove-mobile {
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        &__quantity {
          margin-top: -0.75rem;

          .field.has-addons {
            margin-right: 0.75rem;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            justify-content: space-between;
          }

          p.control button.button {
            border: 0px;
          }

          input.input {
            border: 0px;
          }
        }

        &__quantity > div {
          flex: 33.33%;
        }
      }

      @media screen and (min-width: 375px) and (max-width: 450px) {
        &__quantity {
          .field.has-addons {
            margin-right: 0.5rem;
          }
        }
      }

      @media screen and (max-width: 375px) {
        &__quantity {
          .field.has-addons {
            margin-right: 0px;
          }
        }
      }

      &__quantity {
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 786px) {
          &.desktop {
            display: block;

            &.is-flex {
              display: none !important;
            }
          }

          &.mobile {
            display: none;

            .is-flex {
              display: flex;
            }
          }
        }

        @media screen and (min-width: 787px) {
          &.desktop {
            display: block;
          }
          &:not(:last-child).desktop {
            margin-bottom: 8px;
          }

          &.mobile {
            display: none !important;
          }
        }
      }

      &__price {
        font-size: 16px;
        min-width: 136px;
        text-align: right;

        @media screen and (min-width: 320px) and (max-width: 360px) {
          text-align: left;
        }

        .compared {
          font-size: 12px;
          margin-right: 10px;
          color: var(--grey);
        }

        .upsell-save {
          font-weight: 600;
          font-size: 13px;
          line-height: 24px;
          color: #1d9f54;
        }
      }
    }
    .size {
      &--small {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`
