import React, { FC, useEffect } from 'react'
import css from 'styled-jsx/css'
import Logo from '@ui/components/shared/Logo'
import { freeze, iconPath, iconPathBvf, unFreeze } from '@ui/helpers'
import SfLink from '@ui/components/next/SfLink'
import { LinkNavbar } from './Header'
import { useRouter } from 'next/router'
import { useSettingBvf } from '@ui/hooks'

interface MenuProps extends React.AnchorHTMLAttributes<HTMLDivElement> {
  active: boolean
  toggleModal: () => void
  list: LinkNavbar[]
}

const Menu: FC<MenuProps> = ({ active = false, toggleModal, list }) => {
  const router = useRouter()
  const settingDefaultBvf = useSettingBvf()
  useEffect(() => {
    if (active) {
      freeze()
    } else {
      unFreeze()
    }

    return () => {
      if (active) {
        unFreeze()
      }
    }
  }, [active])

  return (
    <div
      className={`contact-form-menu-modal modal slide-left-desktop slide-left${
        active ? ' is-active' : ''
      }`}
      style={{
        visibility: active ? 'visible' : 'hidden'
      }}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="modal-background" onClick={() => toggleModal()} />
      <div className="modal-card">
        <section className="modal-card-body">
          <div className="logo-and-delete">
            <Logo height={settingDefaultBvf?.height_logo_header || 22} className="navbar-item" />
            <button className="close" onClick={() => toggleModal()}>
              <div className="button-circle-delete">
                <img src={iconPath('delete_black.svg')} alt="cancel" />
              </div>
            </button>
          </div>

          <div className="menu">
            <SfLink pathName={`/`}>
              <p>Home</p>
            </SfLink>
            {list?.map(({ label, subpath, highLight }: LinkNavbar) => (
              <SfLink pathName={`/c/${subpath}`} key={`menu-${subpath}`}>
                <p
                  className={`${highLight ? 'high-light' : ''} ${
                    router.query.collection_path?.includes(subpath || '') ? 'is-active-link' : ''
                  }`}
                >
                  {label}
                </p>
              </SfLink>
            ))}
          </div>
          <div className="support">
            <SfLink pathName="/help-center">
              <img
                src={iconPathBvf('question_circle.svg')}
                className="support__icon"
                alt="question circle"
              />
              Help Center
            </SfLink>

            <SfLink pathName="/trackings/search">
              <img src={iconPathBvf('truck.svg')} className="support__icon" alt="truck" />
              Track your order
            </SfLink>

            <SfLink pathName="/contact">
              <img src={iconPathBvf('phone_call.svg')} className="support__icon" alt="phone" />
              Contact us
            </SfLink>
          </div>
        </section>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .announcement-bar {
    z-index: 31;
  }

  .contact-form-menu-modal {
    .modal-card {
      height: 100vh;
      max-height: 100vh;
      max-width: 330px;
      margin-left: 0;
      color: var(--gray-8);

      @media screen and (max-width: 768px) {
        margin-right: 0;
        max-width: 100vw;
        width: 100vw;
      }

      .modal-card-body {
        background-color: var(--white);
        padding: 0px;
        display: flex;
        flex-direction: column;
      }
    }

    button.close {
      margin-bottom: 0px;
      border: none;
      outline: none;
      background-color: transparent;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .button-circle-delete {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 48px;
      height: 48px;
      background-color: var(--indigo-4);
      border-radius: 100%;
    }

    .navbar-item {
      padding: 0;
      margin: 0;
    }

    .logo-and-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 22px;
      padding: 16px 16px 0px;
    }

    .contact-us-text {
      font-size: 16px;
      line-height: 28px;
    }

    .support {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: start;

      padding: 24px 16px;
      background-color: var(--primary-10);

      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 24px;
      }

      a:last-child {
        margin-bottom: 0px;
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }

    .menu {
      padding: 0px 16px;
      a:last-child {
        margin-bottom: 24px;
      }
    }
    a {
      display: block;
      margin-bottom: 28px;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: var(--gray-8);
      .is-active-link {
        color: var(--primary-100);
      }
      .high-light {
        color: var(--red-700);
      }
    }
  }
`

export default Menu
