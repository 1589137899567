import { useEffect, useRef, useState } from 'react'
import { freeze, unFreeze, countItems, moneyConverter } from '@ui/helpers'
import Variants from './Variants'
import { globalStyle } from './cart-modal-style'
import {
  useMounted,
  usePaygates,
  useResize,
  useFbp,
  useSnapChat,
  useTtq,
  useRedirect,
  useSettingBvf,
  useCookieState
} from '@ui/hooks'
import { logger } from '@ui/analytics'
import {
  useGlobalState,
  useLocalOrder,
  isPayingWithPaypalKey,
  isShowCartModalKey,
  useOrderService
} from '@libs/client'
import PaypalButton from '@ui/components/shared/PaypalButton'
import { useGtag } from '@ui/hooks/useGtag'
import { useNotiContext } from '@ui/components/shared/Notification'

const CartModal = () => {
  const bestsellerPath = useSettingBvf()?.bestseller_path || ''
  const paygates = usePaygates()
  const paypalEx = paygates?.paypalex
  const creditCard = paygates?.creditcard
  const { fbpTrackInitiateCheckout } = useFbp()
  const { snapchatTrackInitiateCheckout } = useSnapChat()
  const { ttqTrackInitiateCheckout, ttqTrackCheckout } = useTtq()
  const { gtagTrackBeginCheckout } = useGtag()
  const [localOrder] = useLocalOrder()
  const variantItems = localOrder?.items ?? []
  const [isPayingWithPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)
  const { gtagTrackViewCart } = useGtag()
  const [syncedActive, setSyncedActive] = useGlobalState(isShowCartModalKey)
  const [isCheckingOut, setIsCheckingOut] = useState(false)
  // const [totalItems, setTotalItems] = useState<number>(countItems(variantItems))
  const [, setSubpaths] = useCookieState('_subpath_', '')

  const { notiDispatch } = useNotiContext()
  const redirect = useRedirect()
  const { initItems } = useOrderService()
  async function onCheckout() {
    if (!creditCard && !paypalEx) {
      return notiDispatch({
        payload: {
          content: `The merchant account has reached processing limit. Please contact us for help.`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }
    setIsCheckingOut(true)

    try {
      logger.logCart('buy')
      setSubpaths(localOrder?.items?.map((x) => x.slug).join(','))
      const result = await initItems(localOrder?.items ?? [], false, true)

      fbpTrackInitiateCheckout(result?.items, result?.items_amount)
      snapchatTrackInitiateCheckout(result?.items, result?.items_amount)
      ttqTrackInitiateCheckout(result?.items, result?.items_amount)
      ttqTrackCheckout(result?.items, result?.items_amount)
      gtagTrackBeginCheckout(result?.items, result?.items_amount)

      redirect('/checkout')
    } catch (err: any) {
      console.error('Create order error', err)

      setIsCheckingOut(false)

      const message = 'Checkout error. Please try again later'
      const notifyContent =
        'The merchant account has reached processing limit. Please contact us for help.'

      if (err?.response?.data?.error === message) {
        notiDispatch({
          payload: { content: notifyContent, type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
      } else {
        notiDispatch({
          payload: { content: 'Checkout failure!', type: 'is-danger' },
          type: 'REMOVE_ALL_AND_ADD'
        })
      }
    }
  }

  useResize((event) => {
    if (event.target) {
      calculateMaxHeight(event.target)
    }
  }, 0)

  useEffect(() => {
    calculateMaxHeight(window)
  }, [syncedActive])

  // useEffect(() => {
  //   const total = countItems(variantItems)
  //   // setTotalItems(total)
  //   if (!total) {
  //     setSyncedActive(false)
  //   }
  // }, [variantItems])

  // function getIsCartActive() {
  //   return variantItems.length && !syncedActive
  // }

  function toggleModal() {
    scrollToTop()
    setSyncedActive(!syncedActive)
  }

  // function openModal() {
  //   toggleModal()
  //   calculateMaxHeight(window)
  // }

  function calculateMaxHeight(element: any) {
    const height = element?.innerHeight
    const width = element?.innerWidth
    if (width <= 768) {
      const vh = height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }

  const scrollRef = useRef(null)
  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        ;(scrollRef.current as any).scrollTo({
          top: (scrollRef.current as any).scrollHeight,
          behavior: 'smooth'
        })
      }, 100)
    }
  }
  const scrollToTop = () => {
    if (scrollRef.current) {
      ;(scrollRef.current as any).scrollTo({
        top: 0
      })
    }
  }

  useEffect(() => {
    if (syncedActive) {
      scrollToBottom()
      freeze()
      gtagTrackViewCart(localOrder?.items, localOrder?.items_amount)
      logger.logProductEvent('open_cart')
    } else {
      unFreeze()
      logger.logCart('close')
    }
  }, [syncedActive])

  const isMounted = useMounted()

  const subTotalItem = localOrder?.items?.reduce((acc, next) => acc + (next?.quantity || 0), 0)

  return (
    <>
      <style jsx>{globalStyle}</style>
      {isMounted && (
        <div className={`cart ${isPayingWithPaypal ? 'is-hidden' : ''}`}>
          <div
            className={`modal slide-right${syncedActive ? ' is-active' : ''}`}
            style={{
              visibility: syncedActive ? 'visible' : 'hidden'
            }}
          >
            <div className="modal-background" onClick={() => toggleModal()}></div>
            <div className="modal-card animated faster">
              <header className="modal-card-head">
                <p className="modal-card-title">Your shopping cart</p>
                <button className="delete" aria-label="close" onClick={() => toggleModal()} />
              </header>
              {/* <section className={`modal-card-body ${isUpdatingCart ? 'disabled' : ''}`}> */}
              {countItems(variantItems) > 0 ? (
                <>
                  <section className="modal-card-body">
                    <div className="scrollable-content" ref={scrollRef}>
                      <Variants />
                    </div>
                    <div className="fixed-bottom">
                      <hr className="fixed-bottom__line" />
                      <div className="total">
                        Subtotal{' '}
                        <span className="total-item">
                          ({subTotalItem} item{(subTotalItem || 0) > 1 ? 's' : ''})
                        </span>
                        <span className="price">
                          ${moneyConverter(localOrder?.items_amount ?? 0)}
                        </span>
                      </div>
                      <button
                        className={`button button--cta is-fullwidth is-primary${
                          isCheckingOut ? ' is-loading' : ''
                        }`}
                        onClick={() => onCheckout()}
                      >
                        PROCEED TO SECURE CHECKOUT
                      </button>
                      {paypalEx ? (
                        <>
                          <div
                            className="is-divider mt-5 mb-5"
                            data-content="or quick checkout with"
                          />
                          <PaypalButton paypalEx={paypalEx} isBuyNow={false} />
                        </>
                      ) : null}
                    </div>
                  </section>
                </>
              ) : (
                <section className="modal-card-body">
                  <div className="empty-content">
                    <div>Looks like your bag is empty.</div>
                    <p>Not sure where to start?</p>
                    <p>Check out our bestsellers.</p>
                    <button
                      onClick={() => {
                        setSyncedActive(false)
                        redirect(`/c/` + bestsellerPath)
                      }}
                      className="button button--cta is-fullwidth is-primary"
                    >
                      Discover our bestsellers
                    </button>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CartModal
