import { FC, HTMLAttributes, useEffect, useState } from 'react'
import useToggle from '@ui/hooks/useToggle'
import Logo from '@ui/components/shared/Logo'
import Menu from '@ui/components/shared/Menu'
import SaleAnnouncement from './SaleAnnouncement'
import AnnouncementBar from './AnnouncementBar'
import {
  expiredDiscountKey,
  isShowCartModalKey,
  useGlobalState,
  useLocalDiscountCode
} from '@libs/client'
import ExpiredDiscount from './ExpiredDiscount'
import SfLink from '../next/SfLink'
import CartModal from '../product/cart/CartModal'
import { useMounted, useSettingBvf } from '@ui/hooks'

import css from 'styled-jsx/css'
import { countItems, iconPath, iconPathBvf } from '@ui/helpers'
import { useRouter } from 'next/router'
import { useLocalOrder } from '@libs/client'
import { logger } from '@ui/analytics'

interface IHeader extends HTMLAttributes<HTMLDivElement> {
  haveSaleBar?: boolean
  redirectable?: boolean
}

export interface LinkNavbar {
  label?: string
  subpath?: string
  highLight?: boolean
}

const Header: FC<IHeader> = ({ haveSaleBar = false, redirectable = true, ...props }) => {
  const [collections] = useGlobalState<S3Types.store_collections | null>('store.collections')
  const CollectionList: LinkNavbar[] = Object.values(collections || {})
    .filter((item) => item?.config?.visible_nav)
    .map((col) => ({
      label: col?.config?.label,
      subpath: col?.config?.slug,
      highLight: col?.config?.high_light
    }))
  const router = useRouter()
  const [localDiscountCode] = useLocalDiscountCode()
  const [active, { toggle }] = useToggle(false)
  const [isExpiredDiscount] = useGlobalState<boolean>(expiredDiscountKey)
  const mouted = useMounted()
  const [showSale, setShowSale] = useState(false)
  const [syncedActive, setSyncedActive] = useGlobalState(isShowCartModalKey)
  const settingDefaultBvf = useSettingBvf()
  useEffect(() => {
    toggle(false)
  }, [router])

  const [localOrder] = useLocalOrder()
  const variantItems = localOrder?.items ?? []
  const [totalItems, setTotalItems] = useState<number>(countItems(variantItems))
  useEffect(() => {
    const total = countItems(variantItems)
    setTotalItems(total)
  }, [variantItems])

  useEffect(() => {
    if (syncedActive && !countItems(variantItems)) setSyncedActive(false)
  }, [])

  return (
    <>
      <div
        {...props}
        className={`header--primary ${isExpiredDiscount ? 'is-expired-discount' : ''} ${
          showSale ? 'show-sale' : ''
        } ${props.className ?? ''}`}
      >
        <style jsx global>
          {globalStyle}
        </style>

        {haveSaleBar &&
          !showSale &&
          !router.query?.p?.toString() &&
          mouted &&
          !localDiscountCode && <AnnouncementBar text={settingDefaultBvf?.anouncement_bar} />}

        {haveSaleBar &&
          (!isExpiredDiscount ? (
            <SaleAnnouncement
              onShowChanged={(show) => {
                setShowSale(show)
              }}
            />
          ) : (
            <ExpiredDiscount />
          ))}
        <nav className="navbar-container container-beva">
          <div className="navbar-container__item navbar-mobile">
            <button className="navbar__item-menu" onClick={() => toggle()}>
              <figure className="menu-logo header-icon">
                <img src={iconPathBvf('menu.svg')} alt="menu" />
              </figure>
            </button>
          </div>
          <div className="navbar-container__item navbar-logo">
            <Logo
              height={settingDefaultBvf?.height_logo_header || 22}
              className="navbar__item-logo"
              redirectable={redirectable}
            />
          </div>
          <ul className="navbar-container__item navbar-links">
            {CollectionList?.map(({ label, subpath, highLight }: LinkNavbar) => (
              <li
                className={`navbar__item-link ${highLight ? 'current_link' : ''} ${
                  router.query.collection_path?.includes(subpath || '') ? 'is-active-link' : ''
                } `}
                key={subpath}
              >
                <SfLink pathName={`/c/${subpath}`}>
                  <p>{label}</p>
                </SfLink>
              </li>
            ))}
          </ul>
          <div className="navbar-container__item navbar-cart">
            <button
              className="navbar__item-badge"
              onClick={() => {
                setSyncedActive(true)
                logger.logProductEvent('cart_icon')
              }}
            >
              <figure className="badge-icon header-icon">
                <img src={iconPath('cart-shopping.svg')} alt="cart" />
              </figure>
              {mouted && (
                <span className={`navbar__item-badge__qty ${!totalItems && 'empty-badge'}`}>
                  {totalItems}
                </span>
              )}
            </button>
          </div>
        </nav>
        {/* {showSale && (
          <nav className="navbar is-light is-transparent has-centered-logo-alt is-hidden-touch">
            <div className="navbar-menu">
              <div className="navbar-start"></div>
              <div className="navbar-brand is-hidden-touch">
                <Logo className="navbar-item" redirectable={redirectable} />
              </div>
              <div className="navbar-end"></div>
            </div>
          </nav>
        )} */}
        <Menu active={active} toggleModal={toggle} list={CollectionList} />
      </div>
      <CartModal />
    </>
  )
}

const globalStyle = css.global`
  .header--primary {
    background-color: var(--white);
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: var(--depth-4);
  }

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0px 12px;

    .navbar-links {
      display: none;

      .navbar__item-link {
        margin: 0 20px;
        a {
          color: var(--gray-8);
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;

          :hover {
            color: var(--primary-100);
          }
        }
      }
      .navbar__item-link.is-active-link {
        a {
          color: var(--primary-100);
        }
      }
      .navbar__item-link.current_link {
        a {
          color: var(--red-700);
        }
      }
    }

    .navbar-cart {
      width: 34px;
      display: flex;
      justify-content: end;
    }

    .navbar-mobile {
      width: 20px;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      .navbar__item-logo {
        figure.image img {
          /* height: 30px; */
        }
      }
      .navbar__item-menu {
        border: 0;
        outline: 0;
        background-color: transparent;
      }

      .navbar__item-badge {
        display: flex;
        padding: 12px 0;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;

        &__qty {
          align-items: flex-start;
          width: 17px;
          height: 17px;
          background-color: var(--red-700);
          color: var(--white);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 11px;
          margin: -8px -4px;
          font-weight: 700;
        }
        .empty-badge {
          background-color: var(--gray-9);
        }
      }
    }

    .header-icon {
      width: 24px;
      height: 24px;
    }
  }

  @media screen and (min-width: 768px) {
    .navbar-container {
      height: 56px;

      .navbar-logo {
        flex: 1;
        justify-content: flex-start;
      }
      .navbar-links {
        display: flex;
        height: 100%;
        flex: 3;
        .navbar__item-link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 3px;
            background: var(--primary-100);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.3s;
          }
          &:hover {
            color: var(--primary-100);
            &::after {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      }
      .navbar-mobile {
        display: none;
      }
      .navbar-cart {
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
`

export default Header
