import { CustomSyncedProp, useMounted, useRedirect, useSetting } from 'src/hooks'
import { useState } from 'react'
import QuantityInput from '@ui/components/shared/QuantityInput'
import { getCurrency, imagePath, sleep } from '@ui/helpers'
import { orderVariantStyle } from './cart-modal-style'
import { useLocalOrder, useOrderService, useTrackingService } from '@libs/client/order/hooks'
import { useGtag } from '@ui/hooks/useGtag'
import { logger } from '@ui/analytics'
import {
  isShowCartModalKey,
  maxItemNegative,
  useGlobalState,
  maxQuantityDiscount
} from '@libs/client'
import { useRouter } from 'next/router'
import { Item } from '@libs/common'

interface mixUpsellOrderType {
  group?: { [slug: string]: itemGroup }
}

type UpsellItemGroup = Item & {
  title?: string
}

interface itemGroup {
  items: UpsellItemGroup[]
  isUpsell?: boolean
  discountPercent?: number
  initiateDiscountPercentUpsell?: number
  pLabel?: string
}

const Variants = () => {
  const [localOrder] = useLocalOrder()
  const router = useRouter()
  const subpath = router.query.subpath?.toString()
  const mounted = useMounted()
  const [isMaxItemNegative] = useGlobalState<{ [slug: string]: boolean }>(maxItemNegative) // isMaxQuantityDiscountItem
  const [maxQuantityValue] = useGlobalState<{ [slug: string]: number }>(maxQuantityDiscount)
  const [, setOpenCartModal] = useGlobalState(isShowCartModalKey)
  const redirect = useRedirect()
  const mixUpsellOrder = () => {
    const listUnixSorted =
      Array.from(new Set(localOrder?.items?.map((x) => x?.page_id || '')?.reverse()))?.reverse() ||
      []

    const mixData: { [pageId: string]: itemGroup } = {}
    listUnixSorted?.forEach((pageId: string) => {
      const dataForSlug = localOrder?.items?.filter((i) => i?.page_id == pageId) || []
      const thisPrd = localOrder?.upsellInfo?.[pageId]
      const isUpsell = thisPrd?.isUpsell
      const discountPercent = thisPrd?.discountPercent || 0
      const initiateDiscountPercentUpsell = thisPrd?.initiateDiscountPercentUpsell || 0
      mixData[pageId] = {
        items: dataForSlug,
        pLabel: (dataForSlug[0] as UpsellItemGroup)?.title?.split('-')[0]?.trim(),
        isUpsell,
        discountPercent,
        initiateDiscountPercentUpsell
      }
    })

    return { group: mixData } as mixUpsellOrderType
  }

  const mixOrder = mixUpsellOrder()

  const onSelectedUpsell = (slug?: string) => {
    if (slug) {
      redirect(`/${slug}`)
      setOpenCartModal(false)
    }
    setTimeout(() => {
      if (process.browser) {
        setOpenCartModal(false)
        document
          ?.querySelector('.product-upsells')
          ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      }
    }, 200)
  }

  return (
    <>
      <style jsx global>
        {orderVariantStyle}
      </style>
      <div className="order-variants">
        <div className="order-variants__bottom"></div>
        <div className="variant-wrapper">
          {mounted &&
            Object.entries(mixOrder?.group || {})?.map(([slug, group], index: number) => {
              const groupQty = group?.items?.reduce((acc, next) => acc + (next?.quantity || 0), 0)
              const productLabel = group?.pLabel
              return (
                <div className="group-item" key={`slug-${slug}-${index}`}>
                  <div className="group-item__label">
                    {productLabel?.toLocaleUpperCase()}{' '}
                    <span className="group-item__qty">
                      ({groupQty} item{groupQty > 1 ? 's' : ''})
                    </span>
                  </div>
                  {group?.items?.map((variant, index) => (
                    <div className="variant-item" key={`slug-${variant?.id}-${index}`}>
                      <Variant
                        {...{
                          variant,
                          isUpsell: group?.isUpsell,
                          index: index,
                          isLastItem: index == group?.items?.length - 1
                        }}
                      />
                    </div>
                  ))}

                  {group?.isUpsell && !isMaxItemNegative?.[slug || ''] && (
                    <div className="upsells">
                      <span className="upsells__text">
                        <span className="upsells__text__bold">
                          EXTRA{' '}
                          {(group?.discountPercent || 0) * maxQuantityValue?.[slug || ''] +
                            (group?.initiateDiscountPercentUpsell || 0)}
                          % OFF
                        </span>{' '}
                        for next item of{' '}
                        {[
                          (productLabel || '')[0]?.toUpperCase(),
                          ...(productLabel || '')?.slice(1)
                        ]?.join('')}
                        {/* )} */}
                      </span>
                      <button
                        className="button button--cta is-primary upsells__button"
                        onClick={() =>
                          onSelectedUpsell(slug == subpath ? '' : group?.items[0]?.slug)
                        }
                      >
                        Select now
                      </button>
                    </div>
                  )}

                  {index < Object.entries(mixOrder?.group || {})?.length - 1 ? (
                    <div className={`cart-divider ${!group?.isUpsell ? 'mt-0' : ''}`}></div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

interface IVariant {
  variant: Types.OrderItem
  index?: number
  isUpsell?: boolean
  isLastItem?: boolean
}

// const mThumb = thumbImage('w=500,h=500')

function Variant({ variant, index = 0, isUpsell = false, isLastItem = false }: IVariant) {
  const { removeItems, updateItems } = useOrderService()
  const [sellpageSetting] = useSetting('sellpage')
  const { gtagTrackRemoveFromCart } = useGtag()
  const { trackingRemoveToCart } = useTrackingService()
  const [isLoading, setIsLoading] = useState(false)

  async function onRemove() {
    setIsLoading(true)
    await sleep(500)
    setIsLoading(false)
    removeItems([variant.id])
    gtagTrackRemoveFromCart(variant, variant.quantity)
    trackingRemoveToCart(variant, variant.quantity)
    logger.logProductEvent('remove_from_cart')
  }

  function getVariantQuantitySyncedProp(): CustomSyncedProp<number> {
    return [
      variant.quantity ?? 0,
      async (quantity: number) => {
        if (variant) {
          updateItems([{ id: variant.id, quantity }])
        }
      }
    ]
  }
  return (
    <>
      <div className="variant">
        <div className="variant--top columns is-mobile is-variable is-2">
          <div className="column is-4">
            <figure className="variant__image image is-square">
              {/* <img src={mThumb(variant.imageUrl ?? '')} alt="" /> */}
              <img src={imagePath(variant.imageUrl ?? '')} alt="" />
            </figure>
          </div>
          <div className="column">
            <div className="columns is-vcentered is-variable is-2 mb-0">
              <div className="column pb-1-mobile">
                <div className="variant__title">{variant?.title}</div>
                <div className="size--small">
                  <div className="variant__property expand-on-mobile">
                    <p className="property mr-0">
                      {Object.entries(variant.properties ?? {}).map(
                        (property: any, i: number, arr: any[]) => {
                          return (
                            <span
                              key={`${variant.id}_${i}`}
                              className={i != arr.length - 1 ? 'mr-6' : ''}
                            >
                              <strong>{property[0]}</strong>: {property[1]}
                            </span>
                          )
                        }
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-4 pt-1-mobile pb-1-mobile is-hidden-mobile">
                <div className="variant__remove">
                  <button
                    // className={`button is-text has-text-link${isRemoving ? ' is-loading' : ''}`}
                    className={`button is-text has-text-link ${isLoading ? ' is-loading' : ''}`}
                    onClick={() => onRemove()}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`variant__quantity desktop is-flex ${
                isUpsell && (index > 0 || (index == 0 && (variant?.quantity || 0) > 1))
                  ? 'show-upsell'
                  : ''
              } ${!isUpsell && isLastItem ? 'mb-0' : ''}`}
            >
              <QuantityInput
                label=""
                isCta={false}
                valueSyncedProp={getVariantQuantitySyncedProp()}
                emitChange={true}
              />
              <div className="variant__price is-pulled-right">
                {variant.compare_price &&
                  variant.price &&
                  variant.compare_price > variant.price && (
                    <del className="compared">{getCurrency(variant.compare_price)}</del>
                  )}
                {getCurrency(variant.price ?? 0)}
                {isUpsell && (index > 0 || (index == 0 && (variant?.quantity || 0) > 1)) && (
                  <div className="upsell-save">
                    You saved {getCurrency((variant?.compare_price || 0) - (variant?.price || 0))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="variant__quantity mobile is-flex">
        <QuantityInput
          label=""
          isCta={false}
          valueSyncedProp={getVariantQuantitySyncedProp()}
          emitChange={true}
          className="mb-0"
        />
        <div className="column is-4 pt-1-mobile pb-1-mobile btn-remove-mobile is-hidden-desktop">
          <div className="variant__remove">
            <button
              className={`button is-text has-text-link ${isLoading ? ' is-loading' : ''}`}
              onClick={() => onRemove()}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="variant__price is-pulled-right">
          {sellpageSetting?.settings?.configurations?.show_compared_price &&
            variant.compare_price &&
            variant.price &&
            variant.compare_price > variant.price && (
              <del className="compared">{getCurrency(variant.compare_price)}</del>
            )}
          {getCurrency(variant.price ?? 0)}
          {isUpsell && (index > 0 || (index == 0 && (variant?.quantity || 0) > 1)) && (
            <div className="upsell-save">
              You saved {getCurrency((variant?.compare_price || 0) - (variant?.price || 0))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Variants
