import { FC } from 'react'
import css from 'styled-jsx/css'

interface IAnnouncementBar {
  text?: string
}

const AnnouncementBar: FC<IAnnouncementBar> = ({ text }) => {
  return (
    <>
      <style jsx global>
        {globalStyle}
      </style>
      {text && <div className="announcement-bar">{text}</div>}
    </>
  )
}

const globalStyle = css.global`
  .announcement-bar {
    z-index: 31;
    width: 100%;
    top: 0;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    background-color: var(--gray-9);
    color: var(--white);
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 4px 12px;
  }
`

export default AnnouncementBar
