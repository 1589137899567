import { useLocalDiscountCode } from '@libs/client'
import { promotion_type } from '@libs/common/models/constant'
import { getTimeArr } from '@ui/helpers'
import { usePromotions, useSetting } from '@ui/hooks'
import { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import css from 'styled-jsx/css'

interface ISaleAnnoucement extends HTMLAttributes<HTMLDivElement> {
  onShowChanged?: (show: boolean) => void
}

const SaleAnnouncement: FC<ISaleAnnoucement> = ({ onShowChanged, ...props }) => {
  const [sellpageSetting] = useSetting('sellpage')
  const promotions = usePromotions()
  const [discountCode] = useLocalDiscountCode()

  const [saleContent, setSaleContent] = useState('')
  const [isHide, setIsHide] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const timeRemainSync = useRef(0)
  const [listTime, setListTime] = useState<any[]>([])
  const countdownInterval = useRef<NodeJS.Timeout | null>()

  const haveData = useMemo(() => saleContent.length > 0 && listTime.length > 0, [
    saleContent,
    listTime
  ])
  const countdownTime = useMemo(
    () => sellpageSetting?.settings?.social_proof?.countdown_time || 788,
    [sellpageSetting]
  )

  const initCountDown = () => {
    if (!timeRemainSync.current) {
      timeRemainSync.current = countdownTime ?? 0
    }
    countdownInterval.current = setInterval(() => {
      if (timeRemainSync.current > 0) {
        timeRemainSync.current--

        setListTime(getTimeArr(timeRemainSync.current))
      } else {
        countdownInterval.current && clearInterval(countdownInterval.current)
        toggleSaleAnnouncement(false)
      }
    }, 1000)
  }

  const toggleSaleAnnouncement = (show: boolean) => {
    setIsHide(!show)
    onShowChanged?.(show)
  }

  useEffect(() => {
    initCountDown()

    const announcementBar = document.getElementsByClassName('announcement-sale-bar__hook')
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setIsSticky(!entry.isIntersecting)
      })
    })
    if (announcementBar[0]) {
      observer.observe(announcementBar[0])
    }

    return () => {
      countdownInterval.current && clearInterval(countdownInterval.current)
      observer?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (discountCode) {
      const promotion = promotions?.[discountCode]
      let content = ''
      if (promotion?.type === promotion_type.SHIP) {
        content = '<span style="font-size: 20px">Free Shipping</span>'
      } else if (promotion?.type === promotion_type.PERCENT && promotion?.value) {
        content = `EXTRA ${promotion.value}% OFF<span class="announcement__sale-content--checkout">at checkout</span>`
      }
      if (content) {
        setSaleContent(content)
      } else {
        toggleSaleAnnouncement(false)
      }
    }
  }, [discountCode])

  useEffect(() => {
    onShowChanged?.(haveData)
  }, [haveData])

  return (
    <div
      {...props}
      className={`announcement-sale-bar ${props.className ?? ''} ${
        !isHide && haveData ? '' : 'announcement-sale-bar--hide'
      }`}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="announcement-sale-bar__hook"></div>
      <div
        className={`announcement-sale-bar__content ${
          isSticky ? 'announcement-sale-bar__content--sticky' : ''
        }`}
      >
        {haveData && (
          <>
            <p
              className="announcement__sale-content"
              dangerouslySetInnerHTML={{ __html: saleContent }}
            ></p>
            <p className="announcement__end-sale">
              Ends
              {listTime.length > 0 &&
                listTime.map((time, idx) => (
                  <span key={idx} className="announcement__time">
                    {time}
                    {listTime?.length - 1 > idx && ':'}
                  </span>
                ))}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .announcement-sale-bar {
    position: relative;
    min-height: 48px;
    pointer-events: none;

    @media screen and (max-width: 768px) {
      min-height: 40px;
    }

    &--hide {
      display: none;
    }
  }
  .announcement-sale-bar__content {
    z-index: 30;
    background-color: var(--gray-2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 12px;

    .announcement {
      &__sale-content {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: var(--red-600);
        display: flex;
        text-transform: uppercase;
        align-items: center;

        &--checkout {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: var(--gray-9);
          text-transform: none;
          margin-left: 8px;
        }
      }

      &__end-sale {
        display: flex;
        align-items: center;
        background: var(--white-100);
        border-radius: 4px;
        padding: 4px 10px;
        margin: 8px 0px 8px 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--gray-9);
        width: 97px;
      }

      &__time {
        display: flex;
        align-items: center;
        font-weight: 600;

        &:first-child {
          margin-left: 4px;
        }
      }
    }

    &--sticky {
      position: fixed !important;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      border-radius: 0 0 4px 4px;
    }
  }

  @media screen and (max-width: 320px) {
    .announcement-sale-bar__content {
      .announcement {
        &__sale-content {
          font-size: 13px;
        }
      }
    }
  }

  @media screen and (min-width: 375px) {
    .announcement-sale-bar__content {
      .announcement {
        &__end-sale {
          margin-left: 32px;
        }
      }
    }
  }

  @media screen and (min-width: 400px) {
    .announcement-sale-bar__content {
      .announcement {
        &__sale-content {
          font-size: 20px;
        }
      }
    }
  }
`

export default SaleAnnouncement
